import { useContext, useMemo, useRef } from 'react';
import _get from 'lodash/get';
import { useQuery } from '@apollo/client';
import getSalePresenter from '../lib/getSalePresenter';
import queryDefs from '../lib/queryDefs';
import useSaleTableState from '../lib/useSaleTableState';
import Alert from './Alert';
import CollectionContext from './CollectionContext';
import Meta from './Meta';
import SaleTable from './SaleTable';
import SaleTableActions from './SaleTableActions';
import SaleTableRows from './SaleTableRows';
import SaleTableToolbar from './SaleTableToolbar';
import { TLDsContext } from './App';
import { flatMap } from 'lodash';
import SaleTableFetchMoreButton from './SaleTableFetchMoreButton';

export default function SaleTablePage({
  emptyResultsDescription = (
    <>
      We weren’t able to find any domains matching the specified criteria. Try
      again using different search filters.
    </>
  ),
  emptyResultsTitle = <>No domains found.</>,
  fetchPolicy,
  hiddenFilters = [],
  nextFetchPolicy,
  query,
  queryVars,
  pageSize = 100,
  pollInterval = undefined,
  salesPath = 'sales',
  showAddToCart = data => false,
  showWatch = data => true,
  title,
  visibleColumns = data => [],
  ...props
}) {
  const scrollParent = useRef();
  const defaultTableState = useMemo(
    () => ({
      ...props.defaultTableState,
    }),
    [props.defaultTableState], // This prop must be memoized!
  );
  const tlds = useContext(TLDsContext);
  const flatTlds = useMemo(() => new Set([...flatMap(tlds)]), [tlds]);
  const state = useSaleTableState(defaultTableState);
  const { data, error, loading, fetchMore } = useQuery(query, {
    pollInterval,
    fetchPolicy,
    nextFetchPolicy,
    variables: {
      ...state.api,
      ...queryVars,
      page: 1,
      pageSize,
      filter: {
        ...(queryVars?.filter ?? {}),
        ...state.api.filter,
        // NOTE: Reducing TLD to a string fixes an issue where data wasn't updating. Maybe try using useLazyQuery instead?
        tld:
          state.api.filter?.tld?.length > 0
            ? state.api.filter.tld.join(',')
            : undefined,
      },
    },
  });
  const resolvedSales = _get(data, salesPath, {});
  const sales =
    resolvedSales.items
      ?.map(sale => getSalePresenter(sale))
      .filter(sale => flatTlds.has(sale.tld)) ?? [];
  const currentPage = _get(data, `${salesPath}.page`, 1);
  const currentPageSize = _get(data, `${salesPath}.pageSize`, pageSize);

  const endingSoon =
    resolvedSales.items
      ?.map(sale => getSalePresenter(sale))
      .filter(
        presenter =>
          presenter.secondsRemaining > 0 && presenter.secondsRemaining <= 3600,
      ) ?? [];

  const hasReachedEnd =
    resolvedSales.items &&
    resolvedSales.items?.length < currentPage * currentPageSize;

  useQuery(queryDefs.salesRealtimeSync, {
    pollInterval: 1000 * 5,
    skip: endingSoon.length === 0,
    variables: {
      ids: endingSoon.map(sale => sale.id),
    },
  });

  if (!data && error) {
    return (
      <div className="tw-wrap">
        <div className="tw-wrap-inner tw-my-4">
          <Alert severity="error" title="Failed to load data" />
        </div>
      </div>
    );
  }

  if (!loading && !_get(data, salesPath)) {
    return (
      <div className="tw-wrap tw-my-4">
        <div className="tw-wrap-inner">
          <Alert severity="error" title="Page not found">
            The page at {window?.location.pathname ?? ''} was not found.
          </Alert>
        </div>
      </div>
    );
  }

  return (
    <>
      {data?.collection && (
        <Meta
          description={data.collection.subheading}
          robots="all"
          title={`${data.collection.heading} | Domain Name Auctions - Namecheap`}
        />
      )}
      <div className="tw-wrap tw-px-4">
        <div className="tw-wrap-inner">
          <h1 className="tw-text-2xl md:tw-text-3xl tw-text-gray-700 tw-font-bold tw-pt-6">
            {data?.collection?.heading ?? title}
          </h1>
        </div>
      </div>
      <CollectionContext.Provider
        value={data?.collection?.visibility === 'public' && data.collection}>
        <SaleTable
          defaultState={defaultTableState}
          initialState={state}
          loading={loading}
          sales={sales}
          total={resolvedSales.total}
          visibleColumns={
            typeof visibleColumns === 'function'
              ? visibleColumns(data)
              : visibleColumns
          }>
          <div className="tw-wrap tw-px-4">
            <div className="tw-wrap-inner">
              <div className="tw-border-b tw-border-gray-100">
                <SaleTableToolbar hiddenFilters={hiddenFilters} />
              </div>
            </div>
          </div>
          <div className="md:tw-wrap" ref={scrollParent}>
            <div className="md:tw-wrap-inner">
              <SaleTableActions
                className="tw-pl-1"
                showAddToCart={
                  typeof showAddToCart === 'function'
                    ? showAddToCart(data)
                    : showAddToCart
                }
                showWatch={
                  typeof showWatch === 'function' ? showWatch(data) : showWatch
                }
              />
              <div className="md:tw-paper tw-overflow-hidden">
                <SaleTableRows
                  showWatch={
                    typeof showWatch === 'function'
                      ? showWatch(data)
                      : showWatch
                  }
                />
                {resolvedSales.items?.length === 0 && (
                  <>
                    <div className="tw-text-center tw-py-16 tw-px-4">
                      <div className="tw-text-lg tw-font-bold">
                        {emptyResultsTitle}
                      </div>
                      <div className="tw-text-sm lg:tw-text-base">
                        {emptyResultsDescription}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          {resolvedSales.items?.length > 0 && !hasReachedEnd && (
            <div className="tw-wrap tw-my-3">
              <SaleTableFetchMoreButton
                onClick={() => {
                  fetchMore({
                    variables: {
                      page: currentPage + 1,
                    },
                  });
                }}
              />
            </div>
          )}
        </SaleTable>
      </CollectionContext.Provider>
    </>
  );
}
